import { useCallback } from 'react';
import { atom, useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useQuery } from '@tanstack/react-query';

import { useFieldMaster } from '../../../hooks/useFieldMaster';
import { SearchCondition, searchConditionListState } from './useSearchCondition';
import { useSelectedOrCurrentYear } from '../../../hooks/useYears';
import { createRequestCondition } from './utils/createRequestCondition';
import { datacrudApiClient as client } from '../../../utils/apiClient';
import type { components } from '../../../schema/data-crud';

const search = async (searchRequest: components['schemas']['SearchRequest']) => {
  const res = await client.POST('/search', {
    body: searchRequest,
  });
  if (res.error) {
    throw new Error(`Failed to POST /search \n message: ${res.error.message}`);
  }
  return res.data;
};

// -----------------------------------------------------------------------------
// Recoil States
// -----------------------------------------------------------------------------

const selectedInfoTypeState = atom<'jian' | 'dispatchInfo' | 'patientInfo'>({
  key: 'useSearch/selectedInfoTypeState',
  default: 'jian',
});

const limitState = atom<number>({
  key: 'useSearch/limitState',
  default: 10,
});

const pageState = atom<number>({
  key: 'useSearch/pageState',
  default: 1,
});

const queriedSearchConditionListState = atom<components['schemas']['SearchRequest']['conditions']>({
  key: 'useSearch/queriedSearchConditionListState',
  default: [],
});

// -----------------------------------------------------------------------------
// Hook
// -----------------------------------------------------------------------------
export const useSearchQuery = () => {
  const target = useRecoilValue(selectedInfoTypeState);
  const year = useSelectedOrCurrentYear();
  const limit = useRecoilValue(limitState);
  const page = useRecoilValue(pageState);
  const conditions = useRecoilValue(queriedSearchConditionListState);
  const searchRequest = { target, year, limit, page, conditions };
  const { data } = useQuery({
    queryKey: ['search', searchRequest],
    queryFn: async () => {
      return await search(searchRequest);
    },
  });
  return { data, target };
};

export const useSearch = () => {
  const fieldMaster = useFieldMaster();
  const [target, setTarget] = useRecoilState(selectedInfoTypeState);
  const [limit, setLimit] = useRecoilState(limitState);
  const [page, setPage] = useRecoilState(pageState);
  const [searchConditionList, setSearchConditionList] = useRecoilState(searchConditionListState);
  const [conditions, setConditions] = useRecoilState(queriedSearchConditionListState);
  const resetList = useResetRecoilState(searchConditionListState);

  const getFieldType = useCallback(
    (fieldId: string) => {
      const field = [...fieldMaster.dispatchInfoCategories, ...fieldMaster.patientInfoCategories]
        .flatMap((category) => category.fields)
        .find((field) => field.fieldId === fieldId);
      return field?.fieldType ?? '';
    },
    [fieldMaster]
  );

  const submit = useCallback(() => {
    const newConditions = searchConditionList.map((condition) =>
      createRequestCondition(
        condition.fieldId,
        getFieldType(condition.fieldId),
        condition.operator,
        condition.value
      )
    );
    setConditions(newConditions);
    setPage(1);
  }, [getFieldType, searchConditionList, setConditions, setPage]);

  // 「事案 <-> 出場情報 <-> 傷病者情報」の変更
  const setInfoType = useCallback(
    (infoType: string) => {
      if (infoType === 'jian' || infoType === 'dispatchInfo' || infoType === 'patientInfo') {
        setTarget(infoType);
        resetList();
      }
    },
    [setTarget, resetList]
  );

  // 検索条件の追加
  const createNewCondition = useCallback(
    (id?: string | undefined) => {
      const newCondition: SearchCondition = {
        id: id ?? Math.random().toString(36).slice(-8), // idがundefinedの時、ランダムな8桁の文字列を生成
        categoryName: '',
        fieldId: '',
        operator: '',
        value: [''],
      };
      setSearchConditionList((prev) => [...prev, newCondition]);
    },
    [setSearchConditionList]
  );

  return {
    selectedInfoType: target,
    setInfoType,
    searchConditionList,
    conditions,
    page,
    setPage,
    limit,
    setLimit: useCallback(
      (limit: number) => {
        setLimit(limit);
        setPage(1);
      },
      [setLimit, setPage]
    ),
    createNewCondition,
    submit,
  };
};
