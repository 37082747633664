import { Suspense } from 'react';
import { useSearch, useSearchQuery } from './hooks/useSearch';
import { JianListTable } from '../../components/JianListTable';
import { ErrorBoundary } from 'react-error-boundary';

export const SearchResult = () => {
  const { conditions } = useSearch();

  return conditions.length === 0 ? (
    <p className="text-gray-500">検索条件を指定してください</p>
  ) : (
    <ErrorBoundary fallback={<p className="text-red-500">検索に失敗しました</p>}>
      <Suspense fallback={<p className="text-darkblue_50">検索中...</p>}>
        <SearchResultTable />
      </Suspense>
    </ErrorBoundary>
  );
};

const SearchResultTable = () => {
  const { data, target } = useSearchQuery();
  const { limit, setLimit, page, setPage } = useSearch();
  const allPageCount = data ? Math.ceil(data.allCount / limit) : 0;

  if (!data) {
    return null;
  }

  if (data.allCount === 0) {
    <p className="text-red-500">条件に該当する事案がありませんでした</p>;
  }

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex items-center gap-2 px-2">
        <div className="text-darkblue_70 text-base">{data.allCount}件の検索結果</div>
        <div className="flex-grow">{/* Spacer */}</div>
        <label className="flex items-center gap-2">
          <div className="font-bold text-sm text-darkblue_70">表示件数</div>
          <select
            className="text-darkblue_90 text-sm rounded border-darkblue_50"
            value={limit}
            onChange={(e) => setLimit(Number(e.target.value))}
          >
            <option value={10}>10件</option>
            <option value={30}>30件</option>
            <option value={50}>50件</option>
            <option value={100}>100件</option>
            <option value={500}>500件</option>
          </select>
        </label>
      </div>
      <div className="flex items-center gap-2 px-2">
        <div className="text-darkblue_90 text-base font-bold">
          {page}ページ目（{(page - 1) * limit + 1}件目から{Math.min(page * limit, data.allCount)}
          件目を表示中）
        </div>
        <div className="flex-grow">{/* Spacer */}</div>
        <div className="flex items-center gap-4">
          {page > 1 && (
            <button
              className="font-bold text-darkblue_90 text-base hover:underline"
              onClick={() => setPage((page) => page - 1)}
            >
              前のページ
            </button>
          )}
          {page < allPageCount && (
            <button
              className="font-bold text-darkblue_90 text-base hover:underline"
              onClick={() => setPage((page) => page + 1)}
            >
              次のページ
            </button>
          )}
        </div>
      </div>
      <JianListTable
        jians={data?.pageItems.map((jian) => ({
          ...jian,
          fields: jian.summaryFields,
          infoType: target === 'patientInfo' ? 'patient-info' : 'dispatch-info',
        }))}
      />
    </div>
  );
};
