import { VFC } from 'react';
import { CategoryButton } from './CategoryButton';
import { useCategoryNames, useSelectCatetory } from '../../../hooks/useJianDetail';

export const CategoryList: VFC = () => {
  const categoryNames = useCategoryNames();
  const { selectedCategoryName, selectCategory } = useSelectCatetory();

  return (
    <div className="border-solid border-2 border-darkblue_100 rounded p-2 h-[calc(100vh-22rem)] lg:h-[calc(100vh-18rem)]  overflow-y-scroll flex flex-col gap-2">
      {categoryNames.map((categoryName) => (
        <CategoryButton
          key={categoryName}
          isSelected={categoryName === selectedCategoryName}
          onSelectCategory={() => selectCategory(categoryName)}
          categoryName={categoryName}
        />
      ))}
    </div>
  );
};
