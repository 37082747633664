import { VFC } from 'react';
import * as Icon from 'react-feather';

import { useSearchCondition } from '../../hooks/useSearchCondition';
import { Select } from '../atoms/Select';
import { Input } from '../atoms/Input';
import { CategorySelector } from './CategorySelector';
import { FieldSelector } from './FieldSelector';
import { OperatorSelector } from './OperatorSelector';

type ConditionBoxProps = { id: string; infoType: 'jian' | 'dispatchInfo' | 'patientInfo' };

export const ConditionBox: VFC<ConditionBoxProps> = ({ id, infoType }) => {
  const {
    searchCondition,
    selectedField,
    setValue,
    setValueFrom,
    setValueTo,
    setMultipleValue,
    deleteCondition,
  } = useSearchCondition(id);

  return (
    <div className="flex items-center space-x-2 p-4 bg-darkblue_20 rounded justify-between">
      <div className="flex flex-wrap gap-2 w-96">
        <CategorySelector id={id} infoType={infoType} />
        <FieldSelector id={id} />
        {selectedField?.fieldType === 'STRING' ? (
          <div className="flex gap-2">
            <OperatorSelector id={id} type="string" />
            <Input
              type="text"
              className="w-52"
              value={searchCondition?.value[0]}
              onChange={(e) => setValue(e.target.value)}
              required
            />
          </div>
        ) : selectedField?.fieldType === 'SINGLECHOICE' ? (
          <div className="flex gap-2">
            <Select
              name="single-choice"
              value={searchCondition?.value[0]}
              onChange={(e) => setValue(e.target.value)}
              required
            >
              <option value="" hidden>
                値を選択
              </option>
              {selectedField.options?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </div>
        ) : selectedField?.fieldType === 'MULTIPLECHOICE' ? (
          <div className="flex gap-2">
            <OperatorSelector id={id} type="multiplechoice" />
            <Select
              name="multiple-choice"
              value={searchCondition?.value}
              onChange={(e) =>
                setMultipleValue(Array.from(e.target.selectedOptions, (option) => option.value))
              }
              multiple
              required
            >
              <option value="" hidden>
                値を選択
              </option>
              {selectedField.options?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </div>
        ) : selectedField?.fieldType === 'INT' ||
          selectedField?.fieldType === 'FLOAT' ||
          selectedField?.fieldType === 'DATE' ||
          selectedField?.fieldType === 'DATETIME' ? (
          <div className="flex gap-2">
            <OperatorSelector id={id} type="numeric" />
            {searchCondition?.operator !== 'between' ? (
              <Input
                type={
                  selectedField?.fieldType === 'DATE'
                    ? 'date'
                    : selectedField?.fieldType === 'DATETIME'
                    ? 'datetime-local'
                    : 'number'
                }
                value={searchCondition?.value[0]}
                step={selectedField?.fieldType === 'DATETIME' ? 1 : undefined}
                onChange={(e) => setValue(e.target.value)}
                className="w-52"
                required
              />
            ) : (
              <div className="flex gap-1 items-center">
                <Input
                  type={
                    selectedField?.fieldType === 'DATE'
                      ? 'date'
                      : selectedField?.fieldType === 'DATETIME'
                      ? 'datetime-local'
                      : 'number'
                  }
                  placeholder="最小値"
                  className="w-24"
                  value={searchCondition?.value[0]}
                  step={selectedField?.fieldType === 'DATETIME' ? 1 : undefined}
                  onChange={(e) => setValueFrom(e.target.value)}
                  required
                />
                <span className="font-bold text-darkblue_100">〜</span>
                <Input
                  type={
                    selectedField?.fieldType === 'DATE'
                      ? 'date'
                      : selectedField?.fieldType === 'DATETIME'
                      ? 'datetime-local'
                      : 'number'
                  }
                  placeholder="最大値"
                  className="w-24"
                  value={searchCondition?.value[1]}
                  step={selectedField?.fieldType === 'DATETIME' ? 1 : undefined}
                  onChange={(e) => setValueTo(e.target.value)}
                  required
                />
              </div>
            )}
          </div>
        ) : null}
      </div>
      <button
        type="button"
        className="flex items-center justify-center border-2 border-darkblue_100 text-darkblue_100 rounded w-8 h-8"
        onClick={deleteCondition}
      >
        <Icon.X size={24} className=" stroke-darkblue_100" />
      </button>
    </div>
  );
};
